<template>
  <div class="page-front animate__animated animate__fadeIn pt-2">
    <!-- Logo Navbar -->
    <div
      class="text-center mb-2"
      v-if="referrer == 'onTheMarket' || referrer == 'amber'"
    >
      <img
        :src="`${this.$urlBucket}/form/partners/${referrer}.png`"
        width="210px"
        :style="{ filter: referrer == 'amber' && 'invert(1)' }"
        alt=""
      />
    </div>

    <div class="text-center mt-3 mb-4" v-else>
      <img :src="`${this.$urlBucket}/form/partners/logo.png`" width="110px" alt="" />
    </div>
    <!-- Logo Navbar -->

    <!-- Banner -->
    <div class="text-center">
      <!-- v-if="referrer =='onTheMarket' || referrer == 'amber'" -->
      <img
        :src="`${this.$urlBucket}/form/assets/${referrer}-banner.png`"
        class="img-fluid mb-4"
        style="display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('spinner-banner');
          }
        "
        @error="(e) => (e.target.src = `${this.$urlBucket}/form/assets/front-form.png`)"
      />
    </div>

    <!-- <div class="text-center" >
      <img
        :src="`${this.$urlBucket}/form/assets/front-form.png`"
        class="img-fluid mb-4"
        style="display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('spinner-banner');
          }
        "
      />
    </div> -->

    <div id="spinner-banner" class="text-center">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <div class="d-flex">
      <div class="items-desc d-flex align-items-center">
        <img
          :src="`${this.$urlBucket}/form/assets/calendar.png`"
          class="img-fluid items-partner"
          alt=""
          srcset=""
        />
        <p class="mb-0">Update your address with everyone in 3 minutes</p>
      </div>
    </div>
    <div class="d-flex">
      <div class="items-desc d-flex align-items-center">
        <img
          :src="`${this.$urlBucket}/form/assets/energy.png`"
          class="img-fluid items-partner"
          alt=""
          srcset=""
        />
        <p class="mb-0">Use SlothMove any time, before or after your move</p>
      </div>
    </div>
    <div class="d-flex">
      <div class="items-desc d-flex align-items-center mb-4">
        <img
          :src="`${this.$urlBucket}/form/assets/odometer.png`"
          class="img-fluid items-partner"
          alt=""
          srcset=""
        />
        <p class="mb-0">Track the progress of your updates in your dashboard</p>
      </div>
    </div>

    <!-- Label -->
    <div
      class="d-flex justify-content-center"
      v-if="referrer == 'onTheMarket'"
    >
      <div class="label-pages d-flex p-2 px-3 align-items-center">
        <img :src="require('@/assets/check-y.png')" width="30px" class="ms-2 me-2" alt="" />
        <p class="ms-1 mb-0" style="font-size: 16px">
          SlothMove address changer is available to you through
          <span style="color: #b5a855">{{ capitalized(referrer) }}</span>
        </p>
      </div>
    </div>

    <div v-else class="d-flex">
      <div class="label-pages d-flex px-3 align-items-center w-100">
        <img
          :src="require('@/assets/check-y.png')"
          width="40px"
          class=""
          style="margin: 0.1em 1.1em 0.1em 0.6em"
          alt=""
        />
        <div>
          <p class="ms-1 mb-0" style="font-size: 16px">
            Enjoy SlothMove address changer from only £{{ priceOffered }}
            <!-- SlothMove address changer costs only £{{ priceOffered }} for unlimited updates -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { navigateTo } from "../router/index.js";
import { scrollToTop } from "../utils/scrollToTop";

// @ is an alias to /src

export default {
  name: "FrontPage",
  components: {},
  data() {
    return {};
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    capitalized(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
  },
  mounted() {
    if (
      this.referrer !== "adv2" &&
      this.referrer !== "amber" &&
      this.referrer !== "onTheMarket"
    ) {
      navigateTo("/home");
    }

    scrollToTop();
    this.$store.commit("setField", [`messageButton`, "GET STARTED"]);

    // document.querySelector(".trustpilot-container").style.display = "none";

    // this.$store.commit("setField", ["titleNavbar", ""]);
    // this.$store.commit("setField", ["step1", true]);

    const validatePage = () => {
      let route = "/home";

      navigateTo(route);
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      // if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
      //   this.$router.back();
      // }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", ["referrer", "baseURL", "urlBucket", "priceOffered"]),
  },
};
</script>
<style lang="scss">
.page-front {
  .items-desc {
    /*max-width: 330px;*/
    p {
      font-size: 23px;
    }
  }

  .items-partner {
    max-height: 55px;
    margin: 0.65em 1em;
  }

  .label-pages {
    padding: 0.7rem;
  }
}
</style>
